interface Product {
  id: number | null;
  count?: number;
  price?: number | null;
}

type Nullable<T> = T | null;

interface NetCatResponse {
  message: string;
  price?: number;
  count?: number;
  responseStatus?: string;
  codeError?: number;
  fields?: string[];
}

/**
 * DOM элемент – Loader, который отображается при слишком долгом ожидании
 * ответа от сервера
 */
const LOADER_ELEMENT: Nullable<HTMLElement> = document.getElementById(
  'mainLoader'
);
/**
 * DOM элемент, в котором выводится информация об операции добавления товара в корзину
 */
const STATUS_ELEMENT: Nullable<HTMLElement> = document.getElementById(
  'cartAddStatus'
);
/**
 * DOM элемент – Форма, в которой содержатся данные пользователя, оформляющего заказ
 */
const CHECKOUT_FORM: Nullable<HTMLElement> = document.getElementById(
  'checkoutForm'
);
/**
 * DOM элемент – контейнер, который содержит в себе итоговую цену
 */
const TOTAL_PRICE_ELEMENT: Nullable<HTMLElement> = document.getElementById(
  'totalCartPrice'
);
const CART_CONTAINER_ELEMENT: Nullable<HTMLElement> = document.getElementById(
  'cartContainer'
);
const CART_COUNT_ELEMENT: Nullable<HTMLElement> = document.getElementById(
  'cartItemsCount'
);
const CART_COUNT_MOBILE_ELEMENT: Nullable<
  HTMLElement
> = document.getElementById('cartCountMobile');

const CART_AMOUNT_CONTAINER_ELEMENT: Nullable<
  HTMLElement
> = document.getElementById('cartPriceContainer');
const CART_ELEMENT: Nullable<HTMLElement> = document.getElementById('cart');

/**
 * DOM элемент, который отвечает за добавление
 * определенного товара в корзину
 */
const ADD_BUTTON_ELEMENT: Nullable<HTMLElement> = document.getElementById(
  'addToCartButton'
);

/**
 * DOM элементы, которые отвечают за удаление
 * определенного товара из корзины
 */
const REMOVE_BUTTON_ELEMENTS: Nullable<Element[]> = Array.from(
  document.getElementsByClassName('btn-remove')
);
/**
 * DOM элементы, которые отвечают за увеличение
 * количества выбранного товара
 */
const PLUS_BUTTON_ELEMENTS: Nullable<Element[]> = Array.from(
  document.getElementsByClassName('b-counter__button_plus')
);
/**
 * DOM элементы, которые отвечают за уменьшение
 * количества выбранного товара
 */
const MINUS_BUTTON_ELEMENTS: Nullable<Element[]> = Array.from(
  document.getElementsByClassName('b-counter__button_minus')
);
/**
 * DOM элемент типа Input,
 * в котором хранится ID товара
 */
const GOOD_ID_ELEMENT: Nullable<HTMLInputElement> = document.querySelector(
  '[name="productID"]'
);
/**
 * DOM элемент типа Input,
 * в котором хранится количество товара
 */
const GOOD_COUNT_ELEMENT: Nullable<HTMLElement> = document.querySelector(
  '.b-counter__value'
);
/**
 * DOM элемент типа Input,
 * в котором хранится цена товара
 */
const GOOD_PRICE_ELEMENT: Nullable<HTMLInputElement> = document.querySelector(
  '[name="productPrice"]'
);

if (ADD_BUTTON_ELEMENT !== null && ADD_BUTTON_ELEMENT !== undefined) {
  ADD_BUTTON_ELEMENT.addEventListener(
    'click',
    async (event: Event): Promise<void> => {
      event.preventDefault();
      event.stopPropagation();
      const self: EventTarget | null = event.target;
      const product: Product = buildProduct();
      await addToCart(product);
    },
    false
  );
}

if (REMOVE_BUTTON_ELEMENTS.length) {
  REMOVE_BUTTON_ELEMENTS.forEach((button: Element): void => {
    button.addEventListener(
      'click',
      async (event: Event): Promise<void> => {
        event.preventDefault();
        event.stopPropagation();
        const self = event.currentTarget as HTMLButtonElement;
        const productID: number | null = self.dataset.productId
          ? parseInt(self.dataset.productId)
          : null;
        const productPrice: number | null = self.dataset.productPrice
          ? parseFloat(self.dataset.productPrice)
          : null;
        const product: Product = {
          id: productID || 0,
          price: productPrice || 0
        };
        await removeFromCart(product);
        removeDOMCartRow(productID || 0);
      },
      false
    );
  });
}

if (PLUS_BUTTON_ELEMENTS.length) {
  PLUS_BUTTON_ELEMENTS.forEach((plusButton: Element): void => {
    plusButton.addEventListener(
      'click',
      (event: Event): void => {
        event.preventDefault();
        event.stopPropagation();
        const self = event.currentTarget as HTMLButtonElement;
        const productID: number | null = self.dataset.productId
          ? parseInt(self.dataset.productId)
          : null;
        const counter: HTMLElement | null = document.getElementById(
          `count-${productID}`
        );

        if (counter) {
          const value: number = parseInt(counter.innerHTML);
          const count: number = value + 1;
          counter.innerHTML = String(count);
          updateProductTotalPrice(productID, count);
        }
      },
      false
    );
  });
}

if (MINUS_BUTTON_ELEMENTS.length) {
  MINUS_BUTTON_ELEMENTS.forEach((minusButton: Element): void => {
    minusButton.addEventListener(
      'click',
      (event: Event) => {
        event.preventDefault();
        event.stopPropagation();
        const self = event.currentTarget as HTMLButtonElement;
        const id: number | null = self.dataset.productId
          ? parseInt(self.dataset.productId)
          : null;
        const counter: HTMLElement | null = document.getElementById(
          `count-${id}`
        );

        if (counter) {
          const value: number = parseInt(counter.innerHTML);
          const count: number = value !== 0 ? value - 1 : 0;
          counter.innerHTML = String(count);
          updateProductTotalPrice(id, count);
        }
      },
      false
    );
  });
}

if (CHECKOUT_FORM !== null && CHECKOUT_FORM !== undefined) {
  CHECKOUT_FORM.addEventListener(
    'submit',
    async (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      const form = <HTMLFormElement>event.target;
      const data: FormData = new FormData(form);
      const requestObject: Object = Object.fromEntries(data.entries());

      const { responseStatus, fields } = await sendOrder(requestObject);
      console.log(responseStatus, fields);
      if (responseStatus === 'success') {
        form.reset();
        setTimeout(() => {
          window.location.href = 'https://bdsm.company/cart/success/';
        }, 500);
      } else {
        if (fields !== null && fields !== undefined) {
          fields.forEach((field: string): void => {
            const DOMElement: Nullable<HTMLElement> = document.getElementById(
              field
            );
            if (DOMElement !== null && DOMElement !== undefined) {
              DOMElement.classList.toggle('is-invalid empty');
            }
          });
        }
      }
    },
    false
  );
}

function updateTotalPrice() {
  if (TOTAL_PRICE_ELEMENT === null || TOTAL_PRICE_ELEMENT === undefined) return;
  const priceElements = <HTMLElement[]>(
    Array.from(document.getElementsByClassName('product__feature_total-price'))
  );

  if (!priceElements.length) return;
  const totalSumm = priceElements
    .map(priceElement => Number(priceElement.dataset.price) || 0)
    .reduce((sum, value) => sum + value, 0);
  TOTAL_PRICE_ELEMENT.innerHTML = formatPrice(totalSumm);
}

function formatPrice(sourcePrice: number): string {
  return new Intl.NumberFormat('ru-RU', { maximumFractionDigits: 2 }).format(
    sourcePrice
  );
}

/**
 * Удаляет DOM элемент - строку с товаром из корзины
 * @param productId
 */
function removeDOMCartRow(productId: number) {
  if (productId !== null && productId !== undefined) {
    const cartRow: Element | null = document.getElementById(`row-${productId}`);
    if (cartRow !== null && cartRow !== undefined) {
      cartRow.classList.add('removable');
      setTimeout(() => {
        cartRow.remove();
        const cartRowElements: number = getDOMCartRows();
        if (cartRowElements) {
          updateTotalPrice();
        } else {
          if (CART_CONTAINER_ELEMENT !== null && CART_ELEMENT !== null) {
            const warnMessage = document.createElement('p');
            warnMessage.className = 'text-white';
            warnMessage.innerHTML =
              'Внимание! Ваша корзина пуста. Для того, чтобы оформить заказ, необходимо выбрать товар из нашего каталога';
            CART_CONTAINER_ELEMENT.removeChild(CART_ELEMENT);
            CART_CONTAINER_ELEMENT.appendChild(warnMessage);
          }
        }
      }, 300);
    }
  }
}

/**
 * Обновить итоговую цену у продукта
 * @param productId
 * @param count
 */
function updateProductTotalPrice(
  productId: number | null,
  count: number
): void {
  if (productId === null) return;

  const DOMProductPrice: Nullable<HTMLElement> = document.getElementById(
    `product-price-${productId}`
  );
  const DOMProductTotalPrice: Nullable<HTMLElement> = document.getElementById(
    `product-total-${productId}`
  );

  if (
    DOMProductPrice !== null &&
    DOMProductPrice !== undefined &&
    DOMProductTotalPrice !== null &&
    DOMProductTotalPrice !== undefined
  ) {
    const price: number | null = DOMProductPrice.dataset.price
      ? parseFloat(DOMProductPrice.dataset.price)
      : null;
    const totalPrice = price ? Math.round(price * count) : 0;
    DOMProductTotalPrice.innerHTML = formatPrice(totalPrice) + '&nbsp;\u20BD';
    DOMProductTotalPrice.dataset.price = String(totalPrice);
    updateProductCartCount({
      id: productId,
      count,
      price
    });
    updateTotalPrice();
  }
}

function buildProduct(): Product {
  return {
    id: GOOD_ID_ELEMENT ? parseInt(GOOD_ID_ELEMENT.value) : 0,
    count: GOOD_COUNT_ELEMENT ? parseInt(GOOD_COUNT_ELEMENT.innerHTML) : 0,
    price: GOOD_PRICE_ELEMENT ? parseFloat(GOOD_PRICE_ELEMENT.value) : 0
  };
}

/**
 * Функция добавления товара в корзину
 * @param {Object} product
 */
async function addToCart(product: Product): Promise<void> {
  try {
    toggleLoader();
    const { message, price, count } = await exequteRequest(
      '/cart/add/',
      product
    );

    if (message === 'success') {
      console.log(count);
      CART_COUNT_MOBILE_ELEMENT
        ? (CART_COUNT_MOBILE_ELEMENT.innerHTML = String(count))
        : null;
      CART_COUNT_ELEMENT
        ? (CART_COUNT_ELEMENT.innerHTML = String(count))
        : null;
      CART_AMOUNT_CONTAINER_ELEMENT
        ? (CART_AMOUNT_CONTAINER_ELEMENT.innerHTML = formatPrice(price || 0))
        : null;

      updateCartStatus(
        '<p class="px-4 py-3">Товар успешно добавлен в корзину</p>'
      );
    }
  } catch (error) {
    updateCartStatus(
      'Ошибка при добавлении товара в корзину, пожалуйста, попробуйте еще раз'
    );
  } finally {
    toggleLoader();
  }
}

/**
 * Функция удаляет товар из корзины
 * @param {Object} product
 */
async function removeFromCart(product: Product): Promise<void> {
  const { message, price, count } = await exequteRequest(
    '/cart/remove/',
    product
  );
  if (message === 'success') {
    CART_COUNT_ELEMENT ? (CART_COUNT_ELEMENT.innerHTML = String(count)) : null;
    CART_COUNT_MOBILE_ELEMENT
      ? (CART_COUNT_MOBILE_ELEMENT.innerHTML = String(count))
      : null;
    CART_AMOUNT_CONTAINER_ELEMENT
      ? (CART_AMOUNT_CONTAINER_ELEMENT.innerHTML = formatPrice(price || 0))
      : 0;
  }
}

/**
 * Функция обновляет количество товара в корзине
 * @param {Object} product
 */
async function updateProductCartCount(product: Product): Promise<void> {
  try {
    toggleLoader();
    const { message, price, count } = await exequteRequest(
      '/cart/update-count/',
      product
    );
    if (message === 'success') {
      CART_COUNT_ELEMENT
        ? (CART_COUNT_ELEMENT.innerHTML = String(count))
        : null;
      CART_COUNT_MOBILE_ELEMENT
        ? (CART_COUNT_MOBILE_ELEMENT.innerHTML = String(count))
        : null;
      CART_AMOUNT_CONTAINER_ELEMENT
        ? (CART_AMOUNT_CONTAINER_ELEMENT.innerHTML = formatPrice(price || 0))
        : 0;
    }
  } catch (error) {
    throw new Error(error);
  } finally {
    setTimeout(() => {
      toggleLoader();
    }, 450);
  }
}

/**
 * Функция отправляет оформленный заказ на сервер
 * @param {Object} order
 */
async function sendOrder(order: Object): Promise<NetCatResponse> {
  try {
    toggleLoader();
    const response = await exequteRequest('/cart/send/', order);
    return response;
  } catch (error) {
    throw new Error(error);
  } finally {
    setTimeout(() => {
      toggleLoader();
    }, 450);
  }
}

/**
 * Утилитраная функция, которая исполняет запрос
 * на сервер и возвращает результат
 * @param {string} url
 * @param {Object} data
 * @returns {Promise}
 */
async function exequteRequest(
  url: string,
  data?: Object
): Promise<NetCatResponse> {
  try {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: data ? JSON.stringify(data) : null
    });
    const result: NetCatResponse = await response.json();
    return result;
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * Утилитарная функция, которая отвечает за отображение/скрытие
 * DOM элемента – загрузчика
 */
function toggleLoader() {
  if (LOADER_ELEMENT !== null && LOADER_ELEMENT !== undefined)
    LOADER_ELEMENT.classList.toggle('opened');
}

/**
 * Утилитарная функция, которая проверяет элемент на null и undefined
 * @param element
 */
function notNil(element: HTMLElement | null): boolean {
  if (!element) return false;
  return element !== null && element !== undefined;
}

/**
 * Утилитарная функция, которая возвращает
 * количество элементов на странице "Корзина"
 */
function getDOMCartRows(): number {
  const cartElementRows: Nullable<Element[]> = Array.from(
    document.getElementsByClassName('b-cart__element-row')
  );
  return cartElementRows ? cartElementRows.length : 0;
}

/**
 * Утилитарная функция, которая отвечает за обновление
 * статуса при добавлении товара в корзину
 * @param message
 */
function updateCartStatus(message: string) {
  if (STATUS_ELEMENT !== null && STATUS_ELEMENT !== undefined) {
    STATUS_ELEMENT.innerHTML = message;

    setTimeout(() => {
      STATUS_ELEMENT.innerHTML = '';
    }, 5000);
  }
}
