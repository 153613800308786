import 'bootstrap';
import 'lightbox2';
import Swiper from 'swiper';
import Inputmask from 'inputmask';

import { Nullable } from './ts/types';

import './ts/utils';
import './ts/cart';

(function() {
  const telephoneInputs: HTMLInputElement[] = <HTMLInputElement[]>(
    Array.from(document.getElementsByClassName('b-input__telephone'))
  );
  const emailInputs: HTMLInputElement[] = <HTMLInputElement[]>(
    Array.from(document.getElementsByClassName('b-input__email'))
  );

  if (emailInputs.length !== 0) {
    const inputmaskEmail = new Inputmask({
      alias: 'email',
      clearIncomplete: true
    });
    emailInputs.forEach((emailInput: HTMLInputElement): void => {
      inputmaskEmail.mask(emailInput);
      emailInput.addEventListener('blur', (event: Event) => {
        const self: HTMLInputElement | null = <HTMLInputElement>event.target;
        if (self !== null && self !== undefined) {
          const regular: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          const value = self.value.trim();
          if (value === '' || !regular.test(String(value).toLowerCase())) {
            self.value = '';
            self.classList.add('is-invalid');
            self.classList.remove('is-valid');
          } else {
            self.classList.add('is-valid');
            self.classList.remove('is-invalid');
          }
        }
      });
    });
  }

  if (telephoneInputs.length !== 0) {
    const inputmaskTelephone = new Inputmask({
      alias: 'phoneru',
      mask: '+9 (999) 999-99-99',
      clearIncomplete: true
    });
    telephoneInputs.forEach((telephoneInput: HTMLInputElement): void => {
      inputmaskTelephone.mask(telephoneInput);
      telephoneInput.addEventListener('blur', (event: Event) => {
        const self: HTMLInputElement | null = <HTMLInputElement>event.target;
        if (self !== null && self !== undefined) {
          const value = self.value.trim();
          if (value === '') {
            self.value = '';
            self.classList.add('is-invalid');
            self.classList.remove('is-valid');
          } else {
            self.classList.add('is-valid');
            self.classList.remove('is-invalid');
          }
        }
      });
    });
  }
})();

(function() {
  const SWIPER_SLIDE_CONTAINERS = Array.from(
    document.getElementsByClassName('b-swiper-catalogue')
  );
  const SWIPER_PRODUCT_GALLERY = document.getElementById('productGallery');
  const SWIPER_PRODUCT_THUMBS = document.getElementById('productThumbs');
  const SWIPER_HERO_BANNER_CONTAINER = document.getElementById(
    'frontpageHeroBanner'
  );
  const SWIPER_PARTNERS_GALLERY = document.getElementById('partnerGallery');
  if (
    SWIPER_PRODUCT_GALLERY !== null &&
    SWIPER_PRODUCT_GALLERY !== undefined &&
    SWIPER_PRODUCT_THUMBS !== null &&
    SWIPER_PRODUCT_THUMBS !== undefined
  ) {
    const thumbs = new Swiper('#productThumbs', {
      slidesPerView: 3,
      spaceBetween: 15,
      watchSlidesVisibility: true,
      watchSlidesProgress: true
    });
    new Swiper('#productGallery', {
      spaceBetween: 10,
      thumbs: {
        swiper: thumbs
      }
    });
  }
  if (
    SWIPER_HERO_BANNER_CONTAINER !== null &&
    SWIPER_HERO_BANNER_CONTAINER !== undefined
  ) {
    const heroBannerID = SWIPER_HERO_BANNER_CONTAINER.getAttribute('id');
    if (heroBannerID)
      new Swiper(`#${heroBannerID}`, {
        centeredSlides: true,
        effect: 'fade',
        grabCursor: true,
        slidesPerView: 1,
        loop: true,
        autoplay: true,
        navigation: {
          nextEl: '.b-swiper-button-next',
          prevEl: '.b-swiper-button-prev'
        }
      });
  }
  if (SWIPER_SLIDE_CONTAINERS.length) {
    SWIPER_SLIDE_CONTAINERS.forEach(slider => {
      const sliderElementID = slider.getAttribute('id');
      new Swiper(`#${sliderElementID}`, {
        grabCursor: true,
        loop: true,
        slidesPerView: 4,
        navigation: {
          nextEl: '.b-swiper-button-next',
          prevEl: '.b-swiper-button-prev'
        },
        breakpoints: {
          1200: {
            slidesPerView: 4
          },
          992: {
            slidesPerView: 2
          },
          768: {
            slidesPerView: 2
          },
          576: {
            slidesPerView: 1
          },
          320: {
            slidesPerView: 1
          }
        }
      });
    });
  }
  if (
    SWIPER_PARTNERS_GALLERY !== null &&
    SWIPER_PARTNERS_GALLERY !== undefined
  ) {
    new Swiper(`#partnerGallery`, {
      grabCursor: true,
      loop: true,
      slidesPerView: 2,
      navigation: {
        nextEl: '.b-swiper-button-next',
        prevEl: '.b-swiper-button-prev'
      },
      breakpoints: {
        1200: {
          slidesPerView: 2
        },
        992: {
          slidesPerView: 2
        },
        768: {
          slidesPerView: 2
        },
        576: {
          slidesPerView: 1
        },
        320: {
          slidesPerView: 1
        }
      }
    });
  }
})();

(function(): void {
  const contactForm = <HTMLFormElement>document.getElementById('contactForm');
  if (contactForm !== null && contactForm !== undefined) {
    contactForm.addEventListener(
      'submit',
      async (event: Event): Promise<void> => {
        event.preventDefault();
        const response = await fetch('/netcat/add.php', {
          method: 'POST',
          mode: 'cors',
          body: new FormData(contactForm)
        });
        const result = await response.json();
        if (result.status === 'error') {
          result.fields.forEach((field: string): void => {
            const DOMElement: Nullable<HTMLElement> = document.getElementById(
              field
            );
            if (DOMElement !== null && DOMElement !== undefined) {
              DOMElement.classList.toggle('is-invalid empty');
            }
          });
        } else {
          const DOMElement = document.getElementById('warnText');
          contactForm.classList.add('hide');
          if (DOMElement !== null && DOMElement !== undefined) {
            DOMElement.innerHTML =
              'Благодарим Вас за отправку сообщения. Наши менеджеры свяжутся с вами в ближайшее время';
          }
          setTimeout(() => {
            contactForm.remove();
            window.location.reload();
          }, 5000);
        }
      },
      false
    );
  }
})();

$(function() {
  $('[data-toggle="tooltip"]').tooltip();
});

(function() {
  const mobileMenuOpenerButton = document.getElementById('mobileMenuButton');
  const mobileMenuWrapper = document.getElementById('mobileMenuWrapper');
  const mobileMenuOverlay = document.getElementById('mobileMenuOverlay');

  if (
    mobileMenuOpenerButton !== null &&
    mobileMenuOverlay !== null &&
    mobileMenuWrapper !== null
  ) {
    mobileMenuOpenerButton.addEventListener(
      'click',
      () => {
        mobileMenuWrapper.classList.toggle('opened');
        mobileMenuOverlay.classList.toggle('opened');
      },
      false
    );
  }
})();
