(function(): void {
  window.addEventListener(
    'load',
    (): void => {
      const forms = <HTMLFormElement[]>(
        Array.from(document.getElementsByClassName('needs-validation'))
      );
      forms.forEach((form: HTMLFormElement): void => {
        form.addEventListener(
          'submit',
          (event: Event): void => {
            if (form.checkValidity() === false) {
              event.preventDefault();
              event.stopPropagation();
            }
            form.classList.add('was-validated');
          },
          false
        );
      });
    },
    false
  );
})();
